<template>
  <v-container fluid style="margin: 0; padding: 0;">
    <Menu style="position:fixed; height: 50px; z-index: 1;" :referral="true" />
    <div style="min-height: calc(100vh - 120px);">
      <v-container
        fluid
        style="height: 100%; margin:0px; padding:0px; min-height: inherit"
        class="d-flex flex-row"
      >
        <div class="list-body">
          <v-card-title>
            <label class="item_title">Referrals</label>
          </v-card-title>
          <v-card-subtitle class="mt-2">
            <label class="item_description">
              Referral list made for some clients:
            </label>
          </v-card-subtitle>
          <v-data-table
            :headers="headers"
            :items="referrals"
            :items-per-page="15"
            :loading="loading"
            @click:row="onSelectReferral"
            class="elevation-0"
            :item-class="selectedClass"
            :search="search"
            :custom-filter="customFilter"
          >
            <template v-slot:[`item.image`]="{ item }">
              <v-avatar class="elevation-6 my-3 ml-5">
                <v-img
                  :src="
                    item.client && item.client.image
                      ? item.client.image
                      : placeholderImage
                  "
                />
              </v-avatar>
            </template>
            <template v-slot:[`item.client_name`]="{ item }">
              <div
                v-html="
                  getFiltered(
                    `${item.client.first_name} ${item.client.last_name}`
                  )
                "
              />
            </template>
            <template v-slot:[`item.partner_name`]="{ item }">
              <div v-html="getFiltered(`${item.organization.name}`)" />
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              <div v-html="getFiltered(getTimeAgo(item.createdAt))" />
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <div v-html="getFiltered(item.type)" />
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div v-html="getFiltered(statusOptions[item.status])" />
            </template>
          </v-data-table>
        </div>
        <div class="right-section" v-if="referrals.length != 0">
          <v-card
            color="white"
            class="mt-5 pb-5"
            style="width: 330px; max-width: 330px"
          >
            <div style="min-height: 400px; overflow-y: scroll;">
              <v-card-text class="pt-4 pb-3">
                <label class="referral_title ml-4">Referral - Details</label>
              </v-card-text>
              <v-card-text>
                <v-row>
                  <v-col sm="6" md="12" cols="12">
                    <div
                      class="rounded-lg d-flex px-3"
                      style="background-color:#fff8; width: 100%; align-items: center;"
                    >
                      <editable-avatar
                        :image="
                          profile.organization
                            ? profile.organization.image
                            : null
                        "
                        :editable="false"
                        :size="70"
                        class="my-3"
                      />
                      <div
                        v-if="profile.organization"
                        class="px-4"
                        style="font-family: 'Poppins-SemiBold';"
                      >
                        <span style="color: #feb934"
                          >Referred Organization</span
                        >
                        <div>
                          <span style="color: #472583">
                            {{ profile.organization.name }}
                          </span>
                        </div>
                        <!-- <div>
                          <span style="color: #6E3DC6">Phone: </span>
                          <span style="color: #472583">
                            {{ formatPhoneNumber(profile.partner.phone) }}
                          </span>
                        </div> -->
                      </div>
                    </div>
                  </v-col>
                  <v-col sm="6" md="12" cols="12">
                    <div
                      class="rounded-lg d-flex px-3"
                      style="background-color:#fff8; width: 100%; align-items: center;"
                    >
                      <editable-avatar
                        :image="profile.client ? profile.client.image : null"
                        :editable="false"
                        :size="70"
                        class="my-3"
                      />
                      <div
                        v-if="profile.client"
                        class="px-4"
                        style="font-family: 'Poppins-SemiBold';"
                      >
                        <span style="color: #feb934">Referred Client</span>
                        <div>
                          <span style="color: #6E3DC6">Name: </span>
                          <span style="color: #472583">
                            {{
                              profile.client.first_name
                                ? profile.client.first_name
                                : ""
                            }}
                            {{ profile.client.last_name }}
                          </span>
                        </div>
                        <div>
                          <span style="color: #6E3DC6">Phone: </span>
                          <span style="color: #472583">
                            {{ formatPhoneNumber(profile.client.phone) }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-title
                class="mx-3"
                style="font-family: 'Poppins-SemiBold'; font-size: 16px;"
              >
                <div style="width: 100%">
                  <span style="color: #472583;">
                    Referred Service
                  </span>
                  <div class="d-flex" style="justify-content: space-between;">
                    <div>
                      <span style="color: #6E3DC6">Type: </span>
                      <span style="color: #472583">{{ profile.type }}</span>
                    </div>
                    <div style="display: none">
                      <span style="color: #6E3DC6">Quantity: </span>
                      <span style="color: #472583">{{ profile.amount }}</span>
                    </div>
                  </div>
                </div>
              </v-card-title>
              <v-card-title
                class="mx-3"
                style="font-family: 'Poppins-SemiBold'; font-size: 16px; "
                v-if="profile.employees"
              >
                <div style="width: 100%">
                  <span style="color: #472583; ">
                    Referred employees
                  </span>
                  <div class="d-flex" style="justify-content: space-between;">
                    <div>
                      <div
                        v-for="employee in profile.employees"
                        :key="employee._id"
                        class="d-flex align-center mb-2"
                      >
                        <editable-avatar
                          :image="employee.image"
                          :editable="false"
                          :shadow="false"
                          :size="40"
                        />
                        <div class="ml-2">
                          {{ employee.first_name }}
                        </div>
                        <div class="ml-2">({{ employee.role }})</div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card-title>
              <v-card-title
                class="mx-3"
                style="font-family: 'Poppins-SemiBold'; font-size: 16px; "
                v-if="profile.description"
              >
                <div style="width: 100%">
                  <span style="color: #472583; ">
                    Referral description
                  </span>
                  <div class="d-flex" style="justify-content: space-between;">
                    <div>
                      <span style="color: gray;">{{
                        profile.description
                      }}</span>
                    </div>
                  </div>
                </div>
              </v-card-title>
              <v-card-title
                class="mx-3"
                style="font-family: 'Poppins-SemiBold'; font-size: 16px; "
              >
                <span style="color: #472583;">
                  Referred status
                </span>
                <v-select
                  :items="statusOptions"
                  v-model="profile.statusLabel"
                  dense
                  rounded
                  outlined
                  persistent-hint
                  background-color="white"
                  v-on:input="onChangedStatus"
                />
              </v-card-title>
            </div>
            <v-card-actions class="mb-3" style="justify-content: space-around;">
              <v-btn small rounded color="#fdbc3a" dark @click="onDelete">
                <v-icon color="white" size="18" class="mr-1">mdi-delete</v-icon>
                Remove
              </v-btn>
              <v-btn small rounded color="blue darken-2" dark @click="onChat">
                <v-icon color="white" size="18" class="mr-1"
                  >mdi-message-text
                </v-icon>
                Chat
              </v-btn>
              <v-btn small rounded color="#7024C4" dark @click="onSave">
                <v-icon color="white" size="18" class="mr-1"
                  >mdi-content-save</v-icon
                >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-container>
    </div>
    <v-dialog v-model="deleteDialog" max-width="528px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this referral? This action can not be
          undone.</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteDialog = !deleteDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="deleteAction"
            :loading="loading"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import Menu from "../../components/core/Menu.vue";
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "../../components/core/EditableAvatar.vue";
import dateFormat from "dateformat";

export default {
  components: { Menu, EditableAvatar },
  data() {
    return {
      isSelectingPhoto: false,
      loading: false,
      errorMessage: null,
      snackbar: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      showPassword: false,
      headers: [
        { text: "", value: "image", sortable: false },
        { text: "Client", value: "client_name" },
        { text: "Organization", value: "partner_name" },
        { text: "Service", value: "type" },
        { text: "Status", value: "status" },
        { text: "Requested At", value: "createdAt" },
      ],
      selected: -1,
      placeholderImage: require("@/assets/default.jpg"),
      profile: { email: "" },
      selectedFile: null,
      deleteDialog: false,
      statusOptions: ["Pending", "Approved", "Completed", "Rejected"],
      search: "",
      referrals: [],
    };
  },
  computed: {
    ...mapGetters({
      allReferrals: "referral/getAllReferrals",
      keyword: "auth/getKeyword",
    }),
  },
  methods: {
    ...mapActions("referral", {
      fetchAllReferrals: "fetchAllReferrals",
      editReferral: "editReferral",
      deleteReferral: "deleteReferral",
    }),
    onChange() {
      this.errorMessage = null;
    },
    onSelectReferral(item) {
      this.selected = this.referrals.indexOf(item);
    },
    getAddressData(addressData) {
      this.profile.address = addressData.name;
      this.profile.state = addressData.administrative_area_level_1;
      this.profile.city = addressData.locality;
      this.profile.zip = addressData.postal_code;
      this.profile.location =
        addressData.latitude + "," + addressData.longitude;
      this.$refs.address.update(this.profile.address);
    },
    getTimeAgo(oldTimeStr) {
      const oldTime = Date.parse(oldTimeStr);
      return dateFormat(oldTime, "mm/dd/yy h:MM TT");
    },
    onChat() {
      console.log(this.profile);
      this.$router.push({
        name: "chat-details",
        params: { id: "inbox" },
        query: {
          _id: this.profile.client._id,
          user_type: "Client",
        },
      });
    },
    onSave() {
      this.loading = true;
      this.editReferral({ status: this.profile.status, _id: this.profile._id })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    onDelete() {
      this.deleteDialog = true;
    },
    deleteAction() {
      this.loading = true;
      this.deleteReferral(this.profile)
        .then(() => {
          this.loading = false;
          if (this.selected == 0 && this.referrals.length > 0) {
            this.profile = this.referrals[0];
            this.profile.statusLabel = this.statusOptions[this.profile.status];
          } else {
            this.selected = 0;
          }
          this.deleteDialog = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    selectedClass(item) {
      if (item._id == this.profile._id) {
        return "selected";
      }
      return "";
    },
    onChangedStatus() {
      var index = this.statusOptions.indexOf(this.profile.statusLabel);
      this.profile.status = index;
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    getFiltered(words) {
      if (!words) return "";
      var filterd = this.$options.filters.highlight(words, this.keyword);
      return filterd;
    },
    customFilter(value, search, item) {
      var searchFilter = false;
      if (this.keyword) {
        if (
          (`${item.client.first_name} ${item.client.last_name}` &&
            `${item.client.first_name} ${item.client.last_name}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (`${item.partner.first_name} ${item.partner.last_name}` &&
            `${item.partner.first_name} ${item.partner.last_name}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.type &&
            item.type.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (this.statusOptions[item.status] &&
            this.statusOptions[item.status]
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.createdAt &&
            this.getTimeAgo(item.createdAt)
              .toUpperCase()
              .includes(this.keyword.toUpperCase()))
        ) {
          searchFilter = true;
        }
      } else {
        searchFilter = true;
      }
      return searchFilter;
    },
  },
  created() {
    this.loading = true;
    this.fetchAllReferrals()
      .then(() => {
        this.loading = false;
        this.selected = 0;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error.response.data.message);
        }
      });
  },
  watch: {
    selected(newValue) {
      if (this.referrals.length > newValue) {
        this.profile = this.referrals[newValue];
        this.profile.statusLabel = this.statusOptions[this.profile.status];
      }
    },
    keyword(newValue) {
      this.search = newValue;
    },
    allReferrals(newValue) {
      if (this.$route.query.new) {
        this.referrals = newValue.filter((item) => {
          var startDate = new Date();
          startDate.setDate(startDate.getDate() - 7);
          var creatTime = new Date(item.createdAt);
          if (creatTime < startDate) {
            return false;
          }
          return true;
        });
      } else {
        this.referrals = newValue;
      }
    },
  },
};
</script>
<style scoped>
.item_title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-left: 5px;
  padding-top: 10px;
}
.item_description {
  color: #482684;
  /* font-family: 'Poppins-Bold'; */
  font-size: 18px;
  padding-left: 7px;
}
.referral_title {
  color: #f0a000;
  font-family: "Poppins-SemiBold";
  font-size: 25px;
}
.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  padding-left: 40px;
  justify-content: center;
  max-width: 200px;
}
.edit_button {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.profile {
  overflow: visible;
}
.referral_description_title {
  color: #7024c4;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
}
.referral_description_item {
  color: #482684;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
}
/* .v-text-field >>> input {
    font-size: 10px;
  } */
.v-text-field >>> .v-text-field__details {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}

.v-data-table >>> .selected {
  background-color: #fff4e0 !important;
}

.list-body {
  width: 100%;
  min-width: 200px;
  margin-left: 60px;
  margin-right: 20px;
  align-items: center;
  padding-top: 50px;
}
.right-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 118px;
  background: #f4f9ff;
  width: 380px;
  min-width: 380px;
  padding-top: 50px;
}
.right-section:hover {
  overflow-y: auto;
}
.right-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.right-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.right-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.right-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
